import validate from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.14.0_db0@0.1.4_eslint@9.18.0_jiti@2.4.2__ior_6ngdftsuaqgtoehb6wgbhytt7y/node_modules/nuxt/dist/pages/runtime/validate.js";
import fetch_45archive_45page_45data_45global from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/middleware/fetchArchivePageData.global.ts";
import fetch_45single_45article_45page_45data_45global from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/middleware/fetchSingleArticlePageData.global.ts";
import manifest_45route_45rule from "/home/gitlab-runner/builds/-zGqAZ5s/0/devhacker/lh-website-client/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.14.0_db0@0.1.4_eslint@9.18.0_jiti@2.4.2__ior_6ngdftsuaqgtoehb6wgbhytt7y/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  fetch_45archive_45page_45data_45global,
  fetch_45single_45article_45page_45data_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}